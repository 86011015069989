.carousel {
  &.slide {
    margin: auto;
    width: 100%;
  }
  &-inner {
    padding-bottom: 40px;
  }
  &-indicators {
    margin-bottom: 0;
    & li {
      background-color: #a6a6a6 !important;
    }
  }
  &-control {
    &-next,
    &-prev {
      background-color: #a6a6a6;
      width: 5% !important;
    }
  }
  &-item-next,
  &-item-prev,
  &-item.active {
    display: -webkit-box !important;
    display: flex !important;
    -webkit-box-pack: center;
    justify-content: center;
  }
}
