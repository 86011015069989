.switch {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0;
  p {
    margin: 0 10px 0 0;
    font-weight: bold;
    font-size: 24px;
    color: #fff;
  }
  .nav__link {
    color: #fff;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    font-weight: bold;
    &:hover {
      text-decoration: none;
    }
  }
  .active {
    background-color: #463c7c70 !important;
    border-color: #463c7c70 !important;
  }
}
