.image-hover-text-container {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.image-hover-image {
  display: block;
}
/* Make the image round */
.image-hover-image img {
  border-radius: 0%;
  width: 400px;
}

.image-hover-text {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  opacity: 0;
  cursor: default;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.image-hover-text:hover {
  opacity: 1;
}

.image-hover-text-bubble {
  position: relative;
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 100%;
  height: 100%;
  text-align: center;
  background: rgba(218, 211, 211, 0.9);
  border-radius: 0%;
  margin: 0 auto;
  padding: 15% 15px;
  overflow: hidden;
  font-size: 17px;
  text-align: center;
  word-wrap: break-word;
}

.image-hover-text .image-hover-text-title {
  font-size: 25px;
  display: block;
}
