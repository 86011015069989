.img-dotted-bg {
  position: relative;
  &:after {
    position: absolute;
    content: "";
    z-index: -1;
    background: url("../../images/dotted.jpg") repeat;
    top: -50px;
    left: -50px;
    width: 200px;
    height: 400px;
  }
  .img-absolute {
    position: absolute;

    max-width: 300px;
    bottom: -20px;
    right: -20px;
  }
}
