@font-face {
  font-family: "Manjari Bold";
  src: url("./../fonts/Manjari/Manjari-Bold.ttf");
}

@font-face {
  font-family: "Manjari Regular";
  src: url("./../fonts/Manjari/Manjari-Regular.ttf");
}

@font-face {
  font-family: "Manjari Thin";
  src: url("./../fonts/Manjari/Manjari-Thin.ttf");
}

@font-face {
  font-family: "Raleway Black";
  src: url("./../fonts/Raleway/Raleway-Black.ttf");
}

@font-face {
  font-family: "Raleway Black Italic";
  src: url("./../fonts/Raleway/Raleway-BlackItalic.ttf");
}

@font-face {
  font-family: "Raleway Bold";
  src: url("./../fonts/Raleway/Raleway-Bold.ttf");
}

@font-face {
  font-family: "Raleway Bold Italic";
  src: url("./../fonts/Raleway/Raleway-BoldItalic.ttf");
}

@font-face {
  font-family: "Raleway Extra Bold";
  src: url("./../fonts/Raleway/Raleway-ExtraBold.ttf");
}

@font-face {
  font-family: "Raleway Extra Bold Italic";
  src: url("./../fonts/Raleway/Raleway-ExtraBoldItalic.ttf");
}

@font-face {
  font-family: "Raleway Extra Light";
  src: url("./../fonts/Raleway/Raleway-ExtraLight.ttf");
}

@font-face {
  font-family: "Raleway Extra Light Italic";
  src: url("./../fonts/Raleway/Raleway-ExtraLightItalic.ttf");
}

@font-face {
  font-family: "Raleway Italic";
  src: url("./../fonts/Raleway/Raleway-Italic.ttf");
}

@font-face {
  font-family: "Raleway Light";
  src: url("./../fonts/Raleway/Raleway-Light.ttf");
}

@font-face {
  font-family: "Raleway Light Italic";
  src: url("./../fonts/Raleway/Raleway-LightItalic.ttf");
}

@font-face {
  font-family: "Raleway Medium";
  src: url("./../fonts/Raleway/Raleway-Medium.ttf");
}

@font-face {
  font-family: "Raleway Medium Italic";
  src: url("./../fonts/Raleway/Raleway-MediumItalic.ttf");
}

@font-face {
  font-family: "Raleway Regular";
  src: url("./../fonts/Raleway/Raleway-Regular.ttf");
}

@font-face {
  font-family: "Raleway Semi Bold";
  src: url("./../fonts/Raleway/Raleway-SemiBold.ttf");
}

@font-face {
  font-family: "Raleway Semi Bold Italic";
  src: url("./../fonts/Raleway/Raleway-SemiBoldItalic.ttf");
}

@font-face {
  font-family: "Raleway Thin";
  src: url("./../fonts/Raleway/Raleway-Thin.ttf");
}

@font-face {
  font-family: "Raleway Thin Italic";
  src: url("./../fonts/Raleway/Raleway-ThinItalic.ttf");
}
