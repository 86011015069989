// TODO:Limpiar las clases
.block-11 .owl-stage {
  padding-top: 30px;
  padding-bottom: 30px
}

.block-11 .owl-nav {
  display: none;
  position: relative;
  position: absolute;
  bottom: -40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%)
}

.block-11 .owl-nav .owl-next,
.block-11 .owl-nav .owl-prev {
  position: relative;
  display: inline-block
}

.block-11 .owl-nav .owl-next:active,
.block-11 .owl-nav .owl-next:focus,
.block-11 .owl-nav .owl-prev:active,
.block-11 .owl-nav .owl-prev:focus {
  outline: none
}

.block-11 .owl-nav .owl-next span,
.block-11 .owl-nav .owl-prev span {
  font-size: 30px;
  padding: 20px
}

.block-11 .owl-nav .owl-next.disabled,
.block-11 .owl-nav .owl-prev.disabled {
  opacity: .2
}

.block-11 .owl-dots {
  text-align: center;
  bottom: -2rem;
  position: relative
}

.block-11 .owl-dots .owl-dot {
  display: inline-block
}

.block-11 .owl-dots .owl-dot>span {
  display: inline-block;
  width: 7px;
  height: 7px;
  background: #ccc;
  margin: 5px;
  border-radius: 50%
}

.block-11 .owl-dots .owl-dot.active>span {
  background: rgb(0, 158,194)
}

.block-33 {
  max-width: 340px;
  margin-left: 20px;
  margin-right: 20px;
}

.block-33 .vcard .image {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 60px;
  flex: 0 0 60px;
  margin-right: 20px
}

.block-33 .vcard .image img {
  max-width: 100%;
  border-radius: 50%
}

.block-33 .vcard .name-text .heading {
  line-height: 1.5;
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 300
}

.block-33 .vcard .name-text .meta {
  color: #a6a6a6;
  display: block
}

.block-33 .text blockquote {
  padding: 0;
  margin-bottom: 0;
  border: none!important;
  font-size: 16px
}

.block-33 .text blockquote p:last-child {
  margin-bottom: 0
}

.block-33 .vcard .image {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 60px;
  flex: 0 0 60px;
  margin-right: 20px
}

.block-33 .vcard .image img {
  max-width: 100%;
  border-radius: 50%
}

.block-33 .vcard .name-text .heading {
  line-height: 1.5;
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 300
}

.block-33 .vcard .name-text .meta {
  color: #a6a6a6;
  display: block
}

.comment-list li .vcard {
  width: 80px;
  float: left
}

.comment-list li .vcard img {
  width: 50px;
  border-radius: 50%
}

.half .image,
.half .text {
    width: 50%
}

.align-self-center {
  -ms-flex-item-align: center!important;
  align-self: center!important
}

.align-self-center {
  -ms-flex-item-align: center!important;
  align-self: center!important
}

.heading {
  color: #000
}







