footer.footer {
  background-color: #c2c4c3;
  color: #fff;

  .footer-link {
    width: 30px;
    height: 30px;
    color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    line-height: 0;
    border-radius: 50%;
    margin: 0 5px;
    border: 1px solid rgba(255, 255, 255, 0.1);

    &:hover {
      background: #fff;
      border-color: #fff;
      color: #c2c4c3;
    }

    li {
      line-height: 1.5;
    }
  }

  .footer-copyright {
    color: #fff;
    font-size: 0.8rem;
  }

  hr {
    width: 100%;
  }
}
