.contact-form-contact-info p {
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 1.5;
  color: #000;
  .icon {
    position: relative;
    width: 0px;
    font-size: 30px;
    color: rgb(0, 158, 194);
  }
}
