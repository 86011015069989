.slider-item {
  //   background-size: cover;
  //   background-repeat: no-repeat;
  //   background-position: top center;
  //   height: calc(100vh);
  //   min-height: 785px;
  //   position: relative;
  background-image: url("../../images/WEB_Quienes-somos-2.png");

  background-attachment: fixed;
  background-position: center;
  // background-repeat: no-repeat;
  background-size: cover;
}

.slider-item .slider-text {
  color: #fff;
  height: 100vh;
  min-height: 100vh;
}

.slider-item .slider-text {
  h1 {
    font-size: 7rem;
    color: #fff;
    line-height: 1.2;
    font-weight: 700 !important;
  }
  span {
    font-size: 30px;
  }
  img {
    width: 100%;
    max-width: 560px;
  }
}

@media (max-width: 991.98px) {
  .slider-item .slider-text h1 {
    font-size: 3rem;
  }
}
